import React, { useEffect, useState } from 'react';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');

  // Fetch events on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://ttht-cms-be9fc385fec3.herokuapp.com/api/events?populate=*');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data); // Log the entire API response

        if (data && data.data) {
          setEvents(data.data);  // Set events from the data array
        } else {
          console.error('Unexpected data structure:', data);
          setError('Unexpected data structure received from the server.');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to fetch events. Please try again later.');
      }
    };

    fetchEvents();
  }, []);

  // Render the events or error message
  return (
    <div>
      <h1>Upcoming Events</h1>
      {error && <p className="error">{error}</p>}
      <div className="event-cards">
        {events.length > 0 ? (
          events.map((event) => {
            // Accessing attributes directly from the event object
            const Title = event.attributes.Title || 'Untitled Event';
            const Date = event.attributes.Date || 'No Date Available';
            const Location = event.attributes.Location || 'No Location Available';
            const Description = event.attributes.Description || 'No Description Available';
            const Image = event.attributes.Image; // Accessing the image correctly

            return (
              <div key={event.id} className="event-card">
                <h2 className="card-title">{Title}</h2>
                <p><strong>Date:</strong> {Date}</p>
                <p><strong>Location:</strong> {Location}</p>
                <p>{Description}</p>
                {Image && Image.data && Image.data.attributes.url ? (
                  <img 
                    src={`https://ttht-cms-be9fc385fec3.herokuapp.com${Image.data.attributes.url}`} 
                    alt={Image.data.attributes.alternativeText || Title || 'Event Image'} 
                    style={{ width: '100%', height: 'auto' }} 
                  />
                ) : (
                  <p>No Image Available</p>
                )}
              </div>
            );
          })
        ) : (
          <p>No events to display.</p>
        )}
      </div>
    </div>
  );
};

export default Events;
