import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import GetInvolved from './pages/GetInvolved';
import Events from './pages/Events';
import Volunteering from './pages/Volunteering';
import Membership from './pages/Membership';
import Template from './components/Template';

function App() {
  return (
    <Router>
      <Template>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path='/events' element={<Events />}/> 
          <Route path='/volunteering' element={<Volunteering />} />
          <Route path='/membership' element={<Membership />} />
        </Routes>
      </Template>
    </Router>
  );
}

export default App;
