import React from 'react';
import { Carousel, Container, Button, Row, Col, Card } from 'react-bootstrap';
import './Home.css'; // Import the custom CSS

function Home() {
  return (
    <Container className="carousel-container">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/slide1.png"
            alt="First slide"
          />
          <div className="carousel-caption">
            <h1>Welcome to Tyne Tees Heritage Transport</h1>
            <p>
              Discover the rich history of transportation in the Tyne Tees region. Join us in preserving and celebrating our heritage.
            </p>
            <Button variant="primary" href="/get-involved">Get Involved</Button>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/Bus_1.jpeg"
            alt="Second slide"
          />
          <div className="carousel-caption">
            <h3>Explore Our Collections</h3>
            <p>Delve into the past with our extensive collection of heritage vehicles.</p>
            <Button variant="primary" href="/collections">Explore</Button>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/Car_Show_1.jpg"
            alt="Third slide"
          />
          <div className="carousel-caption">
            <h3>Join Our Community</h3>
            <p>Become a part of our mission to preserve and promote transport heritage.</p>
            <Button variant="primary" href="/community">Join Us</Button>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* Highlight Section */}
      <section className="highlight-section mt-5">
        <Row>
          <Col md={4}>
            <Card className="text-center mb-4">
              <Card.Body>
                <Card.Title>Our Mission</Card.Title>
                <Card.Text>
                  We aim to preserve and promote the rich history of transportation in the Tyne Tees region.
                </Card.Text>
                <Button variant="primary">Learn More</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-center mb-4">
              <Card.Body>
                <Card.Title>Upcoming Events</Card.Title>
                <Card.Text>
                  Join us for our upcoming events and discover the fascinating world of heritage transport.
                </Card.Text>
                <a href="/events" className="btn btn-primary btn-lg active" role="button" aria-pressed="true">Events</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-center mb-4">
              <Card.Body>
                <Card.Title>Support Us</Card.Title>
                <Card.Text>
                  Help us continue our work by donating, volunteering, or becoming a member.
                </Card.Text>
                <Button variant="primary">Support Us</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default Home;
