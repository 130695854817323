import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './GetInvolved.css';

function GetInvolved() {
  return (
    <Container>
      {/* Header Section */}
      <div className="get-involved-header text-center">
        <h1>Get Involved with Tyne Tees Heritage Transport</h1>
        <p>Join us in preserving and celebrating our transportation heritage.</p>
      </div>

      {/* Ways to Get Involved Section */}
      <section className="ways-to-involve mt-5">
        <h2 className="text-center">How You Can Help</h2>
        <Row className="mt-4">
          <Col md={4}>
            <Card className="involve-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Volunteer" />
              <Card.Body>
                <Card.Title>Volunteer with Us</Card.Title>
                <Card.Text>
                  Lend your skills and time to help us restore vehicles, organize events, and educate the public.
                </Card.Text>
                <Button variant="primary">Learn More</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="involve-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Donate" />
              <Card.Body>
                <Card.Title>Make a Donation</Card.Title>
                <Card.Text>
                  Your donations help us maintain and expand our collection, fund educational programs, and host events.
                </Card.Text>
                <Button variant="primary">Donate Now</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="involve-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Events" />
              <Card.Body>
                <Card.Title>Attend Our Events</Card.Title>
                <Card.Text>
                  Join us at our upcoming events to see our vehicles in action and learn more about our work.
                </Card.Text>
                <Button variant="primary">View Events</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* Join Us Section */}
      <section className="join-us-section mt-5 mb-5 text-center">
        <h2>Become a Member</h2>
        <p>
          Support our mission by becoming a member. You'll receive exclusive updates, invitations to special events, and
          the satisfaction of helping preserve our transportation heritage.
        </p>
        <Button variant="success" size="lg">Join Now</Button>
      </section>
    </Container>
  );
}

export default GetInvolved;
