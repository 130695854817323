import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import "./AboutUs.css"; // Custom styles for About Us page



function AboutUs() {
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const teamMembers = [
    {
      name: "John Kelly",
      role: "Chairman",
      img: "/images/john_profile.png", 
      bio: "I have been involved with the heritage movement for many years and once served as cabinet member for culture with the local authority. During this time, I was chairman of Beamish Museum and lead the Sunderland City of Culture bid, as well as helping bring the Tall Ships race to Sunderland.<br /><br /> My first involvement with classic cars was when I restored a 1964 Austin Cambridge A60. This had more holes in it than net curtains! I have worked with several clubs around creating and holding events; I was part of the team that worked on Washington 50 and helped create videos and books to mark the event. One of the roles I am most proud of is helping get local mining banners back to the Durham Gala.",
    },
    {
      name: "Rob Evans",
      role: "Vice Chairman",
      img: "/images/rob_profile.jpg", 
      bio: "Hello! I’m 61 years old, originally from East London, and raised in the London/Essex borders. I left school at 15 and completed a four-year Toolmaking apprenticeship, gaining skills in both precision and electrical engineering. <br /><br /> Even before I was old enough to drive, I had a passion for cars, starting with my first vehicle—a non-running Mk 3 Cortina (it had to be a Ford in Essex!). At 16, I discovered my love for two-wheeled transport, beginning my journey on a 50cc moped before moving up to a 250cc bike to pass my test.<br /> <br />At 24, I made a significant career change and joined the Metropolitan Police. In 1992, I moved to the North East for love and transferred to Northumbria Police, where I spent 20 of my 30 years as a Dog Handler and Dog Training Instructor before retiring in 2017.<br /> <br />Since retirement, I’ve returned to my passion for vehicles and currently own a classic 1971 MGB Roadster and a 1976 Honda GL1000 Goldwing. I’ve immersed myself in the local classic car and bike scene and proudly serve as a committee member of:<br />The Durham MG and Classic Car Club<br /> <br />Sunderland and District Classic Vehicle Society<br /> Tyne Tees Heritage Transport<br /><br />I’m excited to contribute my experience and passion to our charitable efforts!",
    },
    {
      name: "Stan Stanger",
      role: "Treasurer",
      img: "/images/stan_profile.png", 
      bio: "I have worked in electrical engineering since leaving school, during that time I developed a keen interest in electrical vehicles such as Trams, Trolley buses and heritage transport. <br /><br />Over the years I was a founding member of the North Eastern Electrical Traction Trust and helped bring a collection of Trams, Trolley bus and other public transport together at NELSAM. <br /><br />I am also a founder member of Tyne Tees Heritage Transport Trust and I am working towards the new museum in the North East of England.<br /><br /> I have supported many groups over the years and enjoy the camaraderie of the heritage movement.",
    },
    {
      name: "Trevor Hines",
      role: "To Be Confirmed",
      img: "/images/trevor_profile.jpg",
      bio: "I served in the Merchant Navy for five years before becoming heavily involved in the taxi industry for more years than I care to remember. As well as being the Managing Director of a well known and long established taxi company in the region, I am also the Regional Secretary (North East & Cumbria) of the National Taxi Association. I have therefore gained experience in dealing with the general public, the business community, Council officers, Councillors, and Members of Parliament. I am also very proud to have been an Ambassador for the City of Sunderland for several years.<br /><br />I have always had a great interest in most forms of transport from a very early age, and my main interest now is the preservation of vintage and veteran vehicles of historical significance. I am also very interested in the history of the north east region and establishing a Regional Transport Museum, where vehicles of historical interest can be displayed and enjoyed by the general public and especially the younger generation.<br /><br />Heritage is now universally  accepted as being very important, and my own interests extend to owning several preserved vehicles myself. I therefore appreciate the dedication required in restoring, maintaining, and keeping older vehicles in working order so they can be appreciated by the public at large at events, rallies, and on any other heritage related occasion.",
    },
    {
      name: "David Warren",
      role: "To Be Confirmed",
      img: "/images/david_profile.jpeg",
      bio: "I’m a time-served Electrical Maintenance Engineer with 20 years experience in the renewable energy sector. I manage a team of Commissioning and Installation technicians at the world’s largest offshore wind farm.<br /><br />My passion for vintage vehicles started in my childhood, attending local vintage vehicle shows with my parents. In 1994, I got involved with the preservation of my Scammell Scarab lorry. Since then, my own collection has grown to include a Reliant Scimitar, Morris 1000 and Leyland Atlantean bus.<br /><br />I enjoy restoring vehicles, learning new skills, and connecting with other enthusiasts. I’m also interested in local history and photography.",
    },
    {
      name: "Ryan Kelly",
      role: "Website and Media",
      img: "/images/ryan_profile.jpg",
      bio: "I build things on the internet.",
    },
  ];

  const handleShowModal = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container>
      {/* Header Section */}
      <div className="about-header text-center">
        <h1>About Tyne Tees Heritage Transport</h1>
        <p>
          Preserving the rich history of transportation in the Tyne Tees region.
        </p>
      </div>

      {/* Mission Statement Section */}
      <section className="mission-section mt-5">
        <Row>
          <Col md={6}>
            <Image
              src="/images/Open_Day.jpg"
              fluid
              alt="Open Day"
            />
          </Col>
          <Col md={6}>
            <h2>Our Mission</h2>
            <p>
              Tyne Tees Heritage Transport was founded in 2019 by a group of
              transport enthusiasts from a number of clubs who recognised the
              importance of preserving the region's rich transportation history.
              Over the years, we have grown from a small group of volunteers to
              a thriving organisation that hosts events, educational programs,
              and more. We have successfully restored numerous vehicles and
              continue to expand our collection.
            </p>
          </Col>
        </Row>
      </section>

      {/* History Section */}
      <section className="history-section mt-5">
        <Row>
          <Col md={6} className="order-md-2">
            <Image
              src="/images/Welding.jpg"
              fluid
              alt="Our History"
            />
          </Col>
          <Col md={6} className="order-md-1">
            <h2>Our History</h2>
            <p>
              Tyne Tees Heritage Transport was founded in 2019 by a group of
              transport enthusiasts from a number of clubs who recognised the
              importance of preserving the region's rich transportation history.
              Over the years, we have grown from a small group of volunteers to
              a thriving organisation that hosts events, educational programs,
              and more. We have successfully restored numerous vehicles and
              continue to expand our collection.
            </p>
          </Col>
        </Row>
      </section>

      {/* Team Section */}
      <section className="team-section mt-5 mb-5">
        <h2 className="text-center">Meet Our Team</h2>
        <Row className="mt-4 mb-5">
          {teamMembers.map((member, index) => (
            <Col md={4} key={index}>
              <Card className="team-card text-center">
                <Card.Img variant="top" src={member.img} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text>{member.role}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleShowModal(member)}
                  >
                    View Profile
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      {/* Modal for Team Member Details */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">
            <div className="d-flex flex-column align-items-center">
              <Image
                src={selectedMember?.img}
                roundedCircle
                width={100}
                height={100}
                className="mb-3"
              />
              <span>{selectedMember?.name}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            <strong>Role:</strong> {selectedMember?.role}
          </p>
          <p dangerouslySetInnerHTML={{ __html: selectedMember?.bio }} />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AboutUs;
