import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import './ContactUs.css';

function ContactUs() {
  return (
    <Container>
      {/* Header Section */}
      <div className="contact-header text-center">
        <h1>Contact Tyne Tees Heritage Transport</h1>
        <p>We'd love to hear from you! Reach out to us using the form below or via our contact details.</p>
      </div>

      {/* Contact Form Section */}
      <section className="contact-form-section mt-5">
        <Row>
          <Col md={8}>
            <h2>Get in Touch</h2>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={5} placeholder="Your message" />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-4">
                Send Message
              </Button>
            </Form>
          </Col>

          {/* Contact Details Section */}
          <Col md={4} className="mt-5 mt-md-0">
            <h2>Contact Details</h2>
            <Card className="contact-card">
              <Card.Body>
                <Card.Title><FaEnvelope /> Email</Card.Title>
                <Card.Text>info@ttht.org.uk</Card.Text>
              </Card.Body>
            </Card>
            {/* <Card className="contact-card mt-3">
              <Card.Body>
                <Card.Title><FaPhone /> Phone</Card.Title>
                <Card.Text>+44 1234 567890</Card.Text>
              </Card.Body>
            </Card>
            <Card className="contact-card mt-3">
              <Card.Body>
                <Card.Title><FaMapMarkerAlt /> Address</Card.Title>
                <Card.Text>123 Transport Lane, Tyne Tees, UK</Card.Text>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </section>

      {/* Social Media Section */}
      <section className="social-media-section text-center mt-5 mb-5">
        <h2>Follow Us</h2>
        <p>Stay connected through our social media channels for the latest updates.</p>
        <div className="social-icons">
          <a href="https://www.facebook.com" className="social-icon" aria-label="Facebook"><FaFacebook /></a>
          <a href="https://www.instagram.com" className="social-icon" aria-label="Instagram"><FaInstagram /></a>
        </div>
      </section>
    </Container>
  );
}

export default ContactUs;
