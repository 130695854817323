import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../index.css";

function Template({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Tyne Tees Heritage Transport</title>
      </Helmet>
      <div id="root">
        <Navbar expand="lg" variant="light" className="custom-toggler">
          <Container>
            <Navbar.Brand href="/">Tyne Tees Heritage Transport</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about">About Us</Nav.Link>
                <NavDropdown title="Get Involved" id="navbarDropdown">
                  <NavDropdown.Item href="Membership">Membership</NavDropdown.Item>
                  <NavDropdown.Item href="/volunteering">Volunteering</NavDropdown.Item>
                  <NavDropdown.Item href="/events">Events</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/contact">Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="my-4">{children}</Container>
        <footer className="text-center py-3 mt-auto">
          <Container>
            <p>© 2024 Tyne Tees Heritage Transport. All rights reserved.</p>
          </Container>
        </footer>
      </div>
    </HelmetProvider>
  );
}

export default Template;
