import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Volunteering.css';

function VolunteerOpportunities() {
  const [opportunities, setOpportunities] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const response = await fetch('https://your-strapi-api-url.com/api/volunteer-opportunities?populate=*');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response to inspect its structure

        if (data && data.data) {
          setOpportunities(data.data);
        } else {
          console.error('Unexpected data structure:', data);
          setError('Unexpected data structure received from the server.');
        }
      } catch (error) {
        console.error('Error fetching volunteer opportunities:', error);
        setError('Failed to fetch volunteer opportunities. Please try again later.');
      }
    };

    fetchOpportunities();
  }, []);

  return (
    <Container>
      {/* Header Section */}
      <div className="volunteer-header text-center">
        <h1>Volunteer Opportunities</h1>
        <p>Join us and make a difference in your community.</p>
      </div>

      {/* Opportunities Section */}
      <section className="volunteer-section mt-5">
        <Row>
          {opportunities.map(opportunity => {
            // Destructure necessary fields from the API data
            const { Title, Description, Image } = opportunity.attributes;
            const imageUrl = Image?.data?.attributes?.url; // Correctly accessing the image URL

            return (
              <Col md={4} key={opportunity.id} className="mb-4">
                <Card className="opportunity-card">
                  <Card.Img 
                    variant="top" 
                    src={imageUrl ? imageUrl : "https://via.placeholder.com/150"} // Display the actual image or a placeholder
                    alt={Title} 
                  />
                  <Card.Body>
                    <Card.Title>{Title || "Untitled Opportunity"}</Card.Title>
                    <Card.Text>{Description || "No Description Available"}</Card.Text>
                    <Button variant="primary" href="#">Learn More</Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </section>
      {error && <div className="alert alert-danger">{error}</div>}
    </Container>
  );
}

export default VolunteerOpportunities;
