import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Membership.css';

function Membership() {
  const membershipTiers = [
    {
      id: 1,
      title: "Individual Membership",
      price: "£30/year",
      benefits: [
        "Free entry to all events",
        "Monthly newsletter",
        "10% discount on merchandise",
      ],
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Membership 1" // Replace with the path to your image
    },
    {
      id: 2,
      title: "Family Membership",
      price: "£50/year",
      benefits: [
        "Free entry for the whole family",
        "Monthly newsletter",
        "15% discount on merchandise",
      ],
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Membership 2" // Replace with the path to your image
    },
    {
      id: 3,
      title: "Lifetime Membership",
      price: "£500 one-time",
      benefits: [
        "Free entry to all events for life",
        "Exclusive lifetime member events",
        "25% discount on merchandise",
      ],
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Membership 3" // Replace with the path to your image
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <div className="membership-header text-center">
        <h1>Join Tyne Tees Heritage Transport</h1>
        <p>Become a member and help us preserve transportation history while enjoying exclusive benefits.</p>
        <Button variant="primary" size="lg" href="#tiers">Become a Member</Button>
      </div>

      {/* Membership Benefits Section */}
      <section className="membership-benefits-section mt-5">
        <h2 className="text-center">Why Become a Member?</h2>
        <Row className="mt-4">
          <Col md={4}>
            <h3>Support Our Mission</h3>
            <p>Your membership helps us maintain and restore historic vehicles, and keep our museum running.</p>
          </Col>
          <Col md={4}>
            <h3>Exclusive Benefits</h3>
            <p>Members enjoy discounts, free event entry, and access to members-only events.</p>
          </Col>
          <Col md={4}>
            <h3>Be Part of a Community</h3>
            <p>Join a community of like-minded individuals passionate about preserving transportation heritage.</p>
          </Col>
        </Row>
      </section>

      {/* Membership Tiers Section */}
      <section id="tiers" className="membership-tiers-section mt-5">
        <h2 className="text-center">Membership Tiers</h2>
        <Row className="mt-4">
          {membershipTiers.map(tier => (
            <Col md={4} key={tier.id} className="mb-4">
              <Card className="membership-card">
                <Card.Img variant="top" src={tier.image} alt={tier.title} />
                <Card.Body>
                  <Card.Title>{tier.title}</Card.Title>
                  <Card.Text>
                    <strong>{tier.price}</strong>
                  </Card.Text>
                  <Card.Text>
                    <ul>
                      {tier.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </Card.Text>
                  <Button variant="primary" href="#">Join Now</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
}

export default Membership;
